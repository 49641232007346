<template>
  <div v-if="!isLoggedUser && Object.keys(connectionStatus).length > 0" class="add-contact-btns">
    <button
      class="cancel-request-btn"
      @click="showCancelReqModal()"
      :class="{ 'disable-btn': onReqLoader }"
      v-if="connectionStatus.request_sent"
    >
      Cancel Request
    </button>
    <div
      v-else-if="connectionStatus.request_received"
      class="demo-dropdown-wrap contact-remove-btn"
    >
      <a-dropdown-button
        @click="acceptConnectionRequest($route.params.id, 'accept')"
        :class="{ 'disable-btn': onReqLoader }"
      >
        Confirm Request
        <template #overlay>
          <a-menu>
            <a-menu-item key="1" @click="showDeclineReqModal()">
              <a>Decline Request</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown-button>
    </div>
    <div
      v-else-if="connectionStatus.are_friends"
      class="demo-dropdown-wrap contact-remove-btn"
    >
      <a-dropdown-button :class="{ 'disable-btn': onReqLoader }">
        Connected
        <template #overlay>
          <a-menu>
            <a-menu-item key="1" @click="showRemoveContactModal()">
              <a>Remove Contact</a>
            </a-menu-item>
            <!-- <a-menu-item key="2"> Block contact </a-menu-item> -->
          </a-menu>
        </template>
      </a-dropdown-button>
    </div>
    <button
      v-else
      class="add-btn"
      :class="{ 'disable-btn': onReqLoader }"
      @click="addContact()"
    >
      Connect
    </button>
    <div class="loader" v-if="onReqLoader">
      <the-loader></the-loader>
    </div>
  </div>
  <message-toaster 
  :visible="showMessageModal"
  :message="message"
  @handle-close="showMessageModal = false"
  >
  </message-toaster>
  <!--  Modal for Decline Request -->
  <delete-modal
    :visible="reqDeclineModal"
    @handle-close="reqDeclineModal = false"
    title="Are You Sure You Want To Decline This Request?"
    confirmText="Yes, Decline"
    cancelText="No, Don't Decline"
    :loading="onReqLoader"
    @delete-record="declineConnectionRequest"
  ></delete-modal>
  <!-- Modal for Cancel Request -->
  <delete-modal
    :visible="cancelReqModal"
    @handle-close="cancelReqModal = false"
    title="Are You Sure You Want To Cancel This Request?"
    confirmText="Yes, Cancel"
    cancelText="No, Don't Cancel"
    :loading="onReqLoader"
    @delete-record="handleCancelReq"
  ></delete-modal>
  <!-- Modal for Remove Contact -->
  <delete-modal
    :visible="removeContactModal"
    @handle-close="removeContactModal = false"
    title="Are You Sure You Want To Remove This Contact?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="onReqLoader"
    @delete-record="removeContact"
  ></delete-modal>
</template>

<script>
import TheLoader from "../BaseComponents/TheLoader.vue";
import MessageToaster from '../BaseComponents/MessageToaster.vue';
import DeleteModal from "../BaseComponents/DeleteModal.vue";

export default {
  components: {
    TheLoader,
    MessageToaster,
    DeleteModal,
  },
  props: {
    isLoggedUser: Boolean,
    userId: String,
  },
  data() {
    return {
      onReqLoader: false,
      showMessageModal: false,
      message: {},
      reqDeclineModal: false,
      cancelReqModal: false,
      removeContactModal: false,
    };
  },
 async mounted() {
    // await this.handleConnctionRequest();
    // this.getAllConnection();
  },
  computed: {
    connectionStatus() {
      return this.$store.getters["profile/connectionRequestStatus"];
    },
  },
  methods: {
    async addContact() {
      this.onReqLoader = true;
      try {
        const payload = {
          to_user: this.userId,
          message: "",
        };
        const response = await this.$store.dispatch(
          "profile/sendUserRequest",
          payload
        );
        if (response.status == 201) {
          (this.showMessageModal = true),
            (this.message = {
              title: "Request Sent Successfully",
              // desc: "Thanks for using Glocal",
              type: "success",
            });
          this.handleConnctionRequest();
        }
      } catch (err) {
        console.log(err);
      }
      this.onReqLoader = false;
    },
    async handleConnctionRequest() {
      try {
        const payload = {
          to_user: this.$route.params.id,
        };
        const response = await this.$store.dispatch(
          "profile/handleConnctionRequest",
          payload
        );
        if (response.status == 200) {
          // console.log(response.data);
        }
      } catch (err) {
        console.log(err);
      }
    },
    showCancelReqModal() {
      this.cancelReqModal = true;
    },
    async handleCancelReq() {
      this.onReqLoader = true;
      try {
        const payload = {
          id: this.userId,
        };
        const response = await this.$store.dispatch(
          "profile/handleCancelRequest",
          payload
        );
        if (response.status == 204) {
          (this.cancelReqModal = false),
            (this.showMessageModal = true),
            (this.message = {
              title: "Request Cancel Successfully",
              // desc: "Thanks for using Glocal",
              type: "success",
            });
          this.handleConnctionRequest();
        }
      } catch (err) {
        console.log(err);
      }
      this.onReqLoader = false;
    },
    async acceptConnectionRequest(from_user, action_type) {
      this.onReqLoader = true;
      let payload = {
        action_type: action_type,
        from_user: from_user,
      };
      try {
        const response = await this.$store.dispatch(
          "profile/userRequest",
          payload
        );
        if (response.status == 200) {
          this.handleConnctionRequest();
          (this.showMessageModal = true),
            (this.message = {
              title: "Request Accepted",
              // desc: "Thanks for using Glocal",
              type: "success",
            });
        }
      } catch (err) {
        console.log(err);
      }
      this.onReqLoader = false;
    },
    showDeclineReqModal() {
      this.reqDeclineModal = true;
    },
    async declineConnectionRequest() {
      this.onReqLoader = true;
      let payload = {
        action_type: "reject",
        from_user: this.$route.params.id,
      };
      try {
        const response = await this.$store.dispatch(
          "profile/userRequest",
          payload
        );
        if (response.status == 200) {
          this.handleConnctionRequest();
          this.reqDeclineModal = false;
          (this.showMessageModal = true),
            (this.message = {
              title: "Request Declined",
              // desc: "Thanks for using Glocal",
              type: "success",
            });
        }
      } catch (err) {
        console.log(err);
      }
      this.onReqLoader = false;
    },
    showRemoveContactModal() {
      this.removeContactModal = true;
    },
    async removeContact() {
      this.onReqLoader = true;
      try {
        const payload = {
          id: this.$route.params.id,
        };
        const response = await this.$store.dispatch(
          "profile/removeUser",
          payload.id
        );
        if (response.status == 200) {
          (this.showMessageModal = true),
            (this.message = {
              title: "Removed From Connection",
              // desc: "Thanks for using Glocal",
              type: "success",
            });
          this.handleConnctionRequest();
          this.removeContactModal = false;
        }
      } catch (err) {
        console.log(err);
      }
      this.onReqLoader = false;
    },
    // async getAllConnection() {
    //   try {
    //     const payload = {
    //       user_id: this.$route.params.id,
    //     };
    //     await this.$store.dispatch("profile/getFriendsList", payload);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },
  },
};
</script>

<style lang="scss">
.add-contact-btns {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  button {
    background-color: $color-primary;
    margin-top: 0;
    font-size: 1.6rem;
    padding: 0 3rem;
    height: 4rem;
    border: none;
    color: $color-white;
    cursor: pointer;
    font-family: $font-primary-medium;
  }
  .cancel-request-btn {
    background-color: #e7e7e7;
    color: $color-black;
  }
  .loader {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    background-color: rgba(255, 255, 255, 0.459);
    .ant-spin {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: initial;
      .anticon-loading {
        height: fit-content;
        .anticon-spin {
          width: 3rem;
          height: 3rem;
          color: $color-primary;
        }
      }
    }
  }
  .contact-remove-btn {
    .ant-btn-group {
      .ant-btn {
        background-color: $color-primary;
        margin-top: 0;
        font-size: 1.6rem;
        padding: 0 2rem;
        height: 4rem;
        border: none;
        color: $color-white;
        cursor: pointer;
      }
      .ant-btn-default {
        padding-right: 2rem;
        background-color: #e7e7e7;
        color: $color-black;
        padding-right: 1rem;
      }
      .ant-dropdown-trigger {
        background-color: #e7e7e7;
        box-shadow: none;
        border: none;
        outline: none;
        margin-left: -0.5rem;
      }
      .anticon {
        transform: rotate(90deg);
        font-weight: bold;
        vertical-align: 0;
        color: $color-black;
      }
    }
  }
  .disable-btn {
    pointer-events: none;
    background-color: gray;
    opacity: 0.5;
  }
}
</style>
